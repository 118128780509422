/* Center the table cells */
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    text-align: center;
    padding: 8px; /* Adjust padding as needed */
    border: 1px solid #ddd; /* Add border styles as needed */
}